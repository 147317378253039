import { Box, Typography } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { FAQDto } from "dto/static/faq.dto";
import { useList } from "hooks/useList";
import React from "react";
import { FAQService } from "services/static/faq.service";
import { Status } from "tools/types/status";
import { FAQItem } from "./FAQItem";

import { useLabel } from "hooks/useLabel";

type Props = {
  type: number;
};
const service = new FAQService();

const FAQList: React.FC<Props> = ({ type }) => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<FAQDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("type", [type.toString()]),
      ],
      1,
      -1
    ),
    [type]
  );

  const processItem = (item: FAQDto, index: number) => {
    if (!item) return null;
    return (
      <Box sx={{ width: "100%" }} className="1">
        <FAQItem item={item} key={index} />
      </Box>
    );
  };
  if (loading) return null;
  if (!objects) return null;
  return (
    <Box
      sx={{
        px: { xs: "20px", sm: 10 },
        py: { xs: 4, md: 10 },
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "1200px",
          }}>
          <Typography
            sx={{
              mb: 3,
              fontSize: { xs: "32px", md: "43px" },
              fontWeight: "bold",
              textAlign: "center",
            }}>
            {LL("homePgae_faqTitle")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          // maxWidth: "1500px",
          width: "100%",
          display: "grid",
          gridTemplateColumns: { lg: "1fr 1fr" },
          gap: "0px 50px",
        }}>
        {objects.map((item: FAQDto, index: number) => processItem(item, index))}
      </Box>
    </Box>
  );
};

export { FAQList };
