import React, { useEffect, useState } from "react";

import { IPage } from "interfaces/page.interface";
import { Box, Button, Typography } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { CommonTools } from "tools/utils/common.tool";
import { ProductShortInfo } from "components/product/product/page/ProductShortInfo";
import { DetailItem } from "components/product/product/page/DetailItem";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { ProductBreadcrumb } from "components/product/product/page/ProductBreadcrumb";
import { ProductMedia } from "components/product/product/page/ProductMedia";
import { ButtonAddToCart } from "components/product/product/page/ButtonAddToCart";
import { ProductFilterList } from "components/product/productfilter/ProductFilterList";
import { useLabel } from "hooks/useLabel";
import { Config } from "tools/utils/config";
import { logger } from "tools/utils/logger";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { ProductDialogButton } from "./ProductDialog";
import { useResponsive } from "hooks/useResponsive";
import { Logo } from "components/general/Logo";

const ProductPage: React.FC<IPage> = ({ currentRoute }) => {
  const {
    get,
    product,
    loadingProduct,
    resetState,
    getProductFilters,
    getOffers,
    getSelectedOffer,
  } = useProduct();
  const id = CommonTools.processIdFromPaths(currentRoute, 2);
  const { matchesCustom, downMD } = useResponsive();
  const { LL } = useLabel();
  const [pages, setPages] = React.useState(1);
  const [photoTotalHeight, setPhotoTotalHeight] = React.useState(1);
  const [photoNR, setPhotoNR] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(true);
  const [isParallaxScrollable, setIsParallaxScrollable] = useState(false);
  const footerHeight = 90;

  useEffect(() => {
    setPhotoTotalHeight((product?._galleryActiveList?.length || 0) * 960);
    setPhotoNR(product?._galleryActiveList?.length || 0);
  }, [product?._galleryActiveList]);

  useEffect(() => {
    const photoNumber = (product?._galleryActiveList?.length || 0) - 1;
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    if (viewportHeight > photoNR * 1200) {
      setPages(1);
    } else if (viewportHeight > 1200) {
      setPages((downMD ? 0.75 : 1) + photoNumber * (downMD ? 0.201 : 0.402));
    } else if (photoNR > 1 && viewportHeight < photoNR * 1200) {
      setPages((downMD ? 0.75 : 1) + photoNumber * (downMD ? 0.201 : 0.402));
    } else {
      setPages(1);
    }
  }, [photoTotalHeight]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (currentRoute?._mainobject !== "product") return;
    if (!id) return;
    get(id);
  }, [get, id]);

  useEffect(() => {
    getProductFilters();
  }, [getProductFilters]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => {
    getSelectedOffer();
  }, [getSelectedOffer]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const fullInfoBox = document.getElementById("fullInfoSection");
      const infoBoxTop = fullInfoBox?.offsetTop || 0;
      const parallax = document.querySelector(".paralax1") as HTMLElement;

      if (scrollPosition + window.innerHeight >= infoBoxTop) {
        setIsInfoBoxVisible(false);
        setIsParallaxScrollable(true);
      } else {
        setIsInfoBoxVisible(true);
        setIsParallaxScrollable(false);
      }

      if (bodyHeight - (scrollPosition + windowHeight) < footerHeight) {
        if (parallax) {
          parallax.style.overflow = "hidden";
        }
      } else {
        if (parallax) {
          parallax.style.overflow = "auto";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleInfoBoxClick = () => {
    const fullInfoSection = document.getElementById("fullInfoSection");
    logger("fullInfoSection", fullInfoSection);
    if (fullInfoSection) {
      const elementTop =
        fullInfoSection.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: elementTop - 100,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIsInfoBoxVisible(false);
        setIsParallaxScrollable(true);
      }, 500);
    }
  };

  if (!currentRoute) return null;
  if (!id) return null;
  if (loadingProduct) return null;
  if (!product) return null;

  const alignCenter = { display: "flex", alignItems: "start" };

  return (
    //paralax
    <Box sx={{ height: "100%" }}>
      <Box className="productPageDivMain" sx={{}}>
        <Box className="productTopBlock">
          <ProductBreadcrumb />
        </Box>
        <Parallax key={pages} pages={pages} style={{}} className="paralax1">
          <ParallaxLayer
            sticky={{ start: 0, end: 5 }}
            offset={0}
            style={{
              ...alignCenter,
              justifyContent: "flex-end",
              width: downMD ? "100vw" : "45vw",
              inset: downMD ? "none" : "0px 55vw",
              display: downMD ? "none" : "block",
            }}>
            <Box className="productPageInfo" sx={{}}>
              <Box className="productPageInfoBox">
                <ProductShortInfo item={product} />
                <ProductFilterList />
                <ProductDialogButton product={product} />
                <Box sx={{ mt: 3 }}>
                  <ButtonAddToCart
                    object={product}
                    // onButtonClick={() => {
                    //   onClose("add_to_cart");
                    // }}
                  />
                </Box>
              </Box>
            </Box>
          </ParallaxLayer>

          <ParallaxLayer
            offset={0}
            speed={1.5}
            className="productPagePhoto"
            style={{
              maxHeight: "300px",
              ...alignCenter,
              justifyContent: "flex-start",
              width: downMD ? "100vw" : "50vw",
              inset: downMD ? "0px 0px" : "0px 5vw",
            }}>
            <Box className="productPagePhoto">
              <ProductMedia />
            </Box>
          </ParallaxLayer>
        </Parallax>
      </Box>

      <Box className="photoDivMobile">
        <ProductMedia />
      </Box>
      <Box
        id="fullInfoSection"
        className="productPageInfoMobile"
        sx={{
          minHeight: "300px",
          padding: "20px",
        }}>
        <ProductShortInfo item={product} />
        <ProductFilterList />
        <ProductDialogButton product={product} />
        <Box sx={{ mt: 3, px: { xs: 0, sm: 10, md: 10 } }}>
          <ButtonAddToCart
            object={product}
            // onButtonClick={() => {
            //   onClose("add_to_cart");
            // }}
          />
        </Box>
      </Box>
      {isInfoBoxVisible && (
        <Box className="isInfoBoxVisible" onClick={handleInfoBoxClick}>
          <Box sx={{}} className="popupLogo">
            <Logo width={60} height={60} withLink={false} />
          </Box>
          <Typography className="productShortInfoTitle">
            {CommonTools.processObjectField(product, ["_name"])}
          </Typography>
          <Typography className="productShortInfoCode">
            {LL("clickForMoreInfo")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export { ProductPage };
