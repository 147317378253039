import React, { useState } from "react";

import {
  DisplayProductFilterDictionar,
  SelectedProductFilterDto,
} from "dto/product/productfilter.dto";

import { CommonTools } from "tools/utils/common.tool";
import { ProductFilterDictionarItem } from "./ProductFilterDictionarItem";
import { Box, Typography } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { logger } from "tools/utils/logger";

type Props = {
  item: DisplayProductFilterDictionar;
};

const ProductFilterItemDictionar: React.FC<Props> = ({ item }) => {
  const { selectFilter, selectedFilters } = useProduct();
  const idFilter: number = item.filter.id
    ? typeof item.filter.id === "string"
      ? parseInt(item.filter.id)
      : item.filter.id
    : 0;
  const processDefault = () => {
    if (!selectedFilters) return "";
    if (!selectedFilters.length) return "";
    const filter = selectedFilters.find(
      (object) => object.idfilter == item.filter.id
    );
    if (!filter) return "";
    return filter.idfilterdictionar;
  };
  const [value, setValue] = useState(processDefault());
  // const handleChange = (event: any) => {
  //   const currentValue = event.target.value;
  //   setValue(currentValue);
  //   if (currentValue) {
  //     const newValue = new SelectedProductFilterDto(
  //       idFilter,
  //       parseInt(currentValue)
  //     );
  //     selectFilter(newValue);
  //   }
  // };

  const handleChange = (id: string | number) => {
    setValue(id);

    if (id) {
      const newValue = new SelectedProductFilterDto(
        idFilter,
        parseInt(id.toString())
      );
      selectFilter(newValue);
    }
  };

  const processList = () => {
    if (!item) return null;
    if (!item.dictionarValues) return null;
    if (!item.dictionarValues.length) return null;
    return item.dictionarValues.map((item, index) => {
      const id = CommonTools.processObjectField(item, ["id"]);

      return (
        <ProductFilterDictionarItem
          key={index}
          item={item}
          selected={value === id}
          onSelected={() => handleChange(id)}
        />
      );
    });
  };

  logger("ProductFilterItemDictionar", item);

  if (!item) return null;

  return (
    // <DetailItem
    //   identifier={CommonTools.processObjectField(item, ["filter", "_name"])}
    //   defaultOpen={true}>
    //   <FormControl>
    //     <RadioGroup
    //       row
    //       aria-labelledby="demo-row-radio-buttons-group-label"
    //       name="row-radio-buttons-group"
    //       value={value}
    //       onChange={handleChange}>
    //       {processList()}
    //     </RadioGroup>
    //   </FormControl>
    // </DetailItem>

    // todo

    <Box className="productFilterBoxTitles" sx={{ py: 1 }}>
      <Typography className="filterTitleStyle" sx={{}}>
        {CommonTools.processObjectField(item, ["filter", "_name"])}
      </Typography>
      <Box
        className="button-grid"
        sx={{ justifyContent: { xs: "start", sm: "start" } }}>
        {processList()}
      </Box>
    </Box>
  );
};

export { ProductFilterItemDictionar };
