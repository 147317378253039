import { Box } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CheckoutDto, OrderProducts } from "dto/sale/checkout.dto";

import React, { useState } from "react";
import { OrderService } from "services/sale/order.service";

import { FormCheckout } from "./FormCheckout";
import { useUser } from "hooks/useUser";
import { useCart } from "hooks/useCart";
import { CallbackType } from "interfaces/commontypes.interface";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { useCountry } from "hooks/useCountry";

const service = new OrderService();

const PaymentBlock: React.FC = () => {
  const { user } = useUser();
  const { cartObjects, clearCart } = useCart();
  const { getCountryId, country } = useCountry();
  const [loading, setLoading] = useState(false);

  const onSubmit = (object: CheckoutDto, cb: CallbackType) => {
    if (!object) return;
    object.orderProducts = OrderProducts.fromArray(cartObjects);
    setLoading(true);
    service.checkout(handleSubmit, { cb }, object);
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSubmit = (result: ResultObjectDTO<CheckoutDto>, cbParam: any) => {
    if (!result) {
      onError();
      return;
    }
    if (result.error) {
      onError();
      return;
    }
    if (!result.obj) {
      onError();
      return;
    }
    RouteTools.setHistory(
      `/orderinfo/${CommonTools.processObjectField(result, ["obj", "uuid"])}`,
      {}
    );
    if (cbParam) CommonTools.checkForCallback(cbParam);
    clearCart();
    setLoading(false);
  };
  return (
    <Box className="paymentStepper">
      <FormCheckout
        defaultObject={CheckoutDto.prepareSetDefaultValues(
          cartObjects,
          user,
          getCountryId(country)
        )}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Box>
  );
};

export { PaymentBlock };
