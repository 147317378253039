import { Box } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { SocialSignInDto } from "dto/user/user.dto";
import { useUser } from "hooks/useUser";
import React from "react";
import { SocialIdentifier } from "tools/types/socialidentifier";
const GoogleLoginButton: React.FC = () => {
  const { signInSocial } = useUser();
  const responseMessage = (response?: any) => {
    if (!response) return;
    signInSocial(
      new SocialSignInDto(
        SocialIdentifier.GOOGLE,
        response.credential,
        response
      )
    );
  };

  const errorMessage = (e?: any) => {
    // logger("error Google", e);
  };

  // theme={"outline"} // "outline" | "filled_blue" | "filled_black"
  // text={"signin"} // "signin_with" | "signup_with" | "continue_with" | "signin"
  // logo_alignment={"left"} // "left" | "center"
  // size={"small"} // "small" | "large" | "medium"
  // shape={"rectangular"} // "rectangular" | "pill" | "circle" | "square"
  // https://developers.google.com/identity/gsi/web/reference/js-reference
  // locale={"zh_CN"}

  return (
    <Box className="googleButton">
      <GoogleLogin
        onSuccess={responseMessage}
        onError={errorMessage}
        theme={"outline"}
        text={"signin"}
        logo_alignment={"left"}
        size={"medium"}
      />
    </Box>
  );
};

export { GoogleLoginButton };
