import React from "react";
import { Box, Typography } from "@mui/material";

import { ProductDto } from "dto/product/product.dto";
import { useLabel } from "hooks/useLabel";
import { CommonTools } from "tools/utils/common.tool";
import { ProductOfferFilterList } from "./ProductOfferFilterList";
import { useCountry } from "hooks/useCountry";

import { CartDto } from "dto/sale/cart.dto";

type Props = {
  item: ProductDto;
};

const ProductOffer: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  const { currencyRate, currencyCode } = useCountry();
  const outOfStock = LL("out_of_stock");
  if (!item) return null;
  if (!item.defaultOfferObj) return <Box>{outOfStock}</Box>;
  if (!CommonTools.processObjectField(item, ["defaultOfferObj", "real_price"]))
    return <Box>{outOfStock}</Box>;
  const finalPrice = CartDto.calculateTotalPriceProduct(
    parseFloat(
      CommonTools.processObjectField(item, [
        "defaultOfferObj",
        "real_price",
      ]).toString()
    ),
    1,
    currencyRate
  );
  return (
    <Box>
      {/* <Box className="productItemOffer">
        <ProductOfferFilterList item={item} />
      </Box> */}
      <Box mt={1} className="flexJustifyStart">
        <Typography
          sx={{
            fontSize: "14px",
            // fontWeight: "bold",
          }}>{`${finalPrice.toFixed(2)} ${currencyCode}`}</Typography>
      </Box>
    </Box>
  );
};

export { ProductOffer };
